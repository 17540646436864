/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror ul, .ProseMirror ol {
  padding: 0 1rem;
}
.ProseMirror ul {
  list-style-type: disc;
}
.ProseMirror ol {
  list-style-type: decimal;
}
.ProseMirror h1, .ProseMirror h2, .ProseMirror h3, .ProseMirror h4, .ProseMirror h5, .ProseMirror h6 {
  line-height: 1.1;
  font-weight: 500;
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.9rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

button {
  font-size: inherit;
  font-family: inherit;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
}

.is-active {
  background: black;
  color: #fff;
}

.ProseMirror {
  outline: none;
  padding: 0.7rem;
  border: 2px solid black;
  border-radius: 0.3rem;
}

.ProseMirror h1 {
  font-size: 28px;
  font-weight: 500;
}

.ProseMirror h2 {
  font-size: 24px;
}

.ProseMirror p {
  font-size: 18px;
}