@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

mark {
  padding-bottom: 10px !important;
  background: linear-gradient( 0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 15%, #080E33 15%, #080E33 20%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 100% );
}

* {
  margin: 0;
}

textarea {
  resize: none;
}

.ant-form-item-label {
  text-align: left !important; 
}

.terms a {  
  text-decoration: underline;
}

.ant-switch {
  background-color: #d32f2f !important;
}

.ant-switch-checked {
  background-color: #2fd368 !important;
}

video[poster]{
  height: 200px;
  width: 300px;
  object-fit: fill;
}

.sec-btn {
  background-color: white;
  color: #080E33;
}

.sec-btn:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.primary-btn {
  background-color: #15A3E4;
}

.primary-border {
  border-color: #080E33;
}

.primary-btn:hover {
  background-color: #2d9fd3;
}

.primary-bg {
  background-color: #15A3E4;
}

.border-bg {
  border: 1px solid #15A3E4;
  color: #15A3E4;
}

.border-bg:hover {
  background-color: #15A3E4;
  color: white;
}

.primary-color {
  color: #080E33;
}

.leaflet-container {
  width: 100% !important; 
  height: 100vh !important;
}

.leaflet-popup-content {
  width: 200px !important;
  font-size: 15px !important;
}
.primary-color:hover {
  color: rgba(8, 14, 51, 0.7);
}

.topFeedBox__title {
    max-width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
  .topFeedBox__title {
    max-width: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  } 
}

.cut__me {
  max-width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categorySlide {
  max-height: 350px; 
  overflow-y: auto;
  overflow-x: hidden;  
}

.categorySlide::-webkit-scrollbar {  
  width: 5px;
  height: 5px; 
}

.categorySlide::-webkit-scrollbar-thumb {
  background: #c5c5c5;
}

.ant-divider-horizontal {
  width: 20% !important;
  min-width: 20% !important;
}

.ant-comment {
  width: 100% !important;
}

.ant-tree-list-holder {
  overflow-y: hidden !important;
}

.internal__blog__item {
  width: 200px;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.internal__blog__item_icon {
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

.internal__blog__item__left {
  width: 80%;
}

.showCategories__org::-webkit-scrollbar {
  display: none;
}

.apps > .ant-modal-content {
  border-radius: 5px !important;
}

.apps .ant-modal-body {
  padding: 18px !important;
}

.main__preLoader {
  max-height: 70px;
  animation: laggy_blink 2s ease-in-out infinite;
}

.main__preLoaderContainer {
  height: 85vh;
}

@keyframes laggy_blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1;
  }

}

.event__banner {
    height: 250px;
    width: 100%;
    /* background: black; */
    position: relative;
    overflow: hidden;
}

.event__banner > img {
   object-fit: cover;
   max-height: 100%;
   width: 100%;
   /* filter: blur(1px);
   -webkit-filter: blur(1px); */
}

.event__banner > .event__section {
    position: absolute;
    bottom: 30px;
    left: 40px;
    z-index: 2;
}

.event__banner > .event__section > h1 {
  color: white;
}

.gmap > iframe {
  min-height: 250px;
}

.brief__item {
  min-width: 300px;
  max-width: 300px;
  margin-left: 15px;
}

.c__item {
  min-width: 300px;
  max-width: 300px;
  margin-left: 15px;
}

.brief__item > img {
  max-width: 100%;
  min-height: 300px;
  object-fit:cover;
  /* max-height: 200px; */
}

.event__select .ant-select-selector {
  padding: 8px !important;
  border-radius: 5px !important;
}

.home__select .ant-select-single {
 height: 40px !important;
}

.home__select .ant-select-selection-item:focus {
  outline: none !important;
}

.breif__title {
  width: 200px;
  text-overflow: ellipsis !important;
}

.community__header {
  position: sticky;
  top: 0px;
  z-index: 10;
}

.community__avatar {
  width: 120px;
  height: 120px;
}

.mockupImage {
  max-height: 600px;
}

.home__card:hover {
  background-color: white;
}

.home__card:hover > p {
  color: black;
}

.home__card:hover > h2 {
  color: black;
}

.hero__image {
  object-fit: cover;
  max-height: 100%;
}

.hero__2 {
  object-fit: cover;
  height: 300px;
  width: 400px;
}

.screen_hero {
  object-fit: contain;
  max-height: 900px;
}

.memory__item {
 padding: 0;
 margin: 0;
 padding-right: 2px;
 padding-top: 2px;

 color: white;
 overflow: hidden;
 position: relative;
 height: 250px;
}

.memory__item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.memory__overlay {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: auto;
  bottom: 0;
  padding: 20px;
}

.memory__caption {
  font-size: 16px;
  width: 100%;
  max-height: 250px;
  text-align: left;
}

.memory__overlay > span {
  width: 100%;
  margin-bottom: 10px;
  font-size: 30px;
}

.memory__overlay:hover {
  background: rgba(0, 0, 0, 0.2);
}

.memory__overlay:hover > .memory__caption {
  display: none;
}

.notification__bubble {
  position: absolute;
  bottom: 11px;
  left: 8px;
  font-size: 12px;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification__container {
  position: absolute;
  top: 45px;
  width: 390px;
  height: auto;
  max-height: 500px;
  right: -200px;
  overflow-y: auto;
  z-index: 999;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  
  border-bottom: 12px solid #e2e8f0;
  position: absolute;  
  top: 33px;
  z-index: 1000;
}

.inbox__container {
  min-height: 550px;
}

.inbox__area {
  min-height: 300px;
  background-color: #080E33;
}