.slider__parent {
    /* max-width: 100%; */
    /* width: 800px; */
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    display: flex;
    flex-wrap: no-wrap;
    max-width: 100%;
    max-height: auto;
}

.slider__parent::-webkit-scrollbar {
    display: none;
}

.slider__item {
    height: 230px;
    min-width: 470px;
    background: black;
    position: relative;
    overflow: hidden;
}

@media only screen and (max-width: 600px) { 
    .slider__item {
        height: 200px;
        min-width: 300px;
    }
    section > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
    }
    section {
        position: absolute;
        bottom: 30px;
        left: 20px !important;
        z-index: 2;
    }
    .slider__right {
        display: none;
    }
    .slider__left {
        display: none;
    }
}

.slider__item > .slider__img {
   object-fit: cover;
   opacity: 0.4;
   background-color: white;
   max-height: 300px;
   width: 100%;
   filter: blur(1px);
   -webkit-filter: blur(1px);
}

.slider__item > .slider__imgDefault {
    object-fit: contain;
    opacity: 0.4;
    background-color: white;
    max-height: 300px;
    width: 100%;
    filter: blur(1px);
    -webkit-filter: blur(1px);
}

.slider__item > section {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 2;
}

.slider__item > section > h1 {
    color: white;
}

.slider__right {
    margin-left: 10px;
}

.slider__left {
    margin-right: 10px;
}

.slider__itemDescription {
    color: rgba(255, 255, 255, 0.748) !important;
}