.cooky {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
}

.cookystrong {
    padding-top: 12px;
    padding-bottom: 12px;
}

.cookypara {
    font-size: 18px;
    padding-bottom: 20px;
    text-align: justify;
}

.cookyitem {
    font-size: 16px;
}