.homeBox__image {
    object-fit: contain; 
    padding: 10px;
}

.homeBox__left {
    flex: 0.8;
}

.homeBox__description {
    display: -webkit-box -moz-box;    
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 100px;
}

.homeBox__hoverImage {    
    max-width: 200px;
    overflow: hidden;
}

.homeBox__hoverImage img {    
    transition: all .2s ease-in-out;
}

.homeBox__hoverImage:hover img {
    transform: scale(1.1);
}