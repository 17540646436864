
.feedback {
    position: fixed;
    bottom: 0;
    right: 25px;
    z-index: 1000;
}

.feedback__modal {       
    height: auto;
    max-height: 100%;
    /* position: fixed;
    bottom: 0;
    right: 25px; */
    z-index: 1000;
}

@media all and (min-width: 1600px) {
    .feedback__modal {
        overflow-y: auto;
        height: auto;
    }
}


.feedback__emotion:hover {    
    border-color: #718096 !important;
}

.feedback__input {
    width: 85%;   
    resize: none !important;  
}

.feedback__btn {
    width: 85%;
}