.contact {         
    display: flex;  
    flex-direction: column;
    align-items: flex-start;
}

.contact__inputGroup {    
    padding: 5px;
}

.contact__inputGroup > p {
    font-weight: 600;    
    font-size: 15px;
}