.app {
    text-align: center;
    height: 90vh;
    width: 100%;    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app > h1 {
    font-weight: 800;
    color: black;    
    margin-bottom: 0px;
}

.app > h1 > span {    
    color: orange;    
}

.profile__avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
    padding: 2px;
    border: 2px solid #2d9fd3;
}

.rbc-btn-group:nth-child(3) {
    display: none !important;
}